.global_item-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.all_items-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 15px;
    padding: 15px;
}

.single_item-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
    padding: 15px;
    gap: 5px;
    border-radius: 35px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: rgba(17, 17, 17, 0.4);
    text-align: center;
}

.single_item-container:hover{
    transition: all 0.3s ease;
    -webkit-box-shadow: 0px 2px 10.5px -3.5px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 2px 10.5px -3.5px rgba(0,0,0,0.55);
    box-shadow: 0px 2px 10.5px -3.5px rgba(0,0,0,0.55);
}

@media(max-width: 768px) {
    .global_item-container{
        width: 100%;
        justify-content: center;
        gap: 0;
    }
}