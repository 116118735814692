.searchField_container{
    width: 450px;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    background-color: white;
}

@media(max-width: 690px) {
    .searchField_container{
        width: 100%;
        background-color: #f2f2f2;
        border: none;
    }
}