.tvoi-input{
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 15px;
    font-size: 14px;
    transition: 0.2s;
}

.tvoi-input:focus{
    transition: 0.2s;
    border: 1px solid #232323;
    outline: none;
}

.tvoi-button{
    padding: 10px 15px;
    border: none;
    border-radius: 15px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s;
}

.fly-position{
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 10;
}

.fly-button-container{
    border-radius: 15px;
    padding: 15px; 
    background-color: #fff;
    overflow: hidden; 
    display: inline-block; 
    position: relative;
    transition: 0.2s ease;
}

.fly-icon-style{
    color: #60a5fa;
    font-size: 1.6rem;
}

@media screen and (max-width: 620px) {
    .fly-position{
        bottom: 90px;
        right: 10px;
    }
}