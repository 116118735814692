@media(max-width: 770px) {
    .filter-panel-element {
        padding: 10px 10px !important;
    }
}

.filter-panel-container {
    position: relative;
}
  
.filter-panel-element {
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding: 10px 25%;
    background-color: white;
}

.filter-panel-element::-webkit-scrollbar {
    display: none;
}

.filter-panel-container button{
    min-width: 150px;
    background-color: #f2f2f2;
    border: none;
    border-radius: 20px;
    color: black;
    text-transform: lowercase;
    font-size: 12px;
    padding: 10px;
}